var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "queryStatistics" },
    [
      _c(
        "Row",
        { staticStyle: { "flex-wrap": "nowrap" }, attrs: { gutter: 4 } },
        [
          _c(
            "Col",
            { attrs: { span: _vm.outSpan } },
            [
              _c("Alert", { staticClass: "alert" }, [_vm._v("人员信息")]),
              _c(
                "Card",
                [
                  _c(
                    "Row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "Col",
                        { attrs: { span: "24" } },
                        [
                          _c(
                            "Form",
                            {
                              ref: "queryForm",
                              attrs: { "label-width": 70, inline: "" },
                            },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检编号" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      placeholder: "请输入体检编号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.queryFrom.testNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryFrom, "testNum", $$v)
                                      },
                                      expression: "queryFrom.testNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "FormItem",
                                { attrs: { label: "体检单位" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "185px" },
                                      attrs: {
                                        "label-in-value": "",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.queryFrom.orderId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryFrom,
                                            "orderId",
                                            $$v
                                          )
                                        },
                                        expression: "queryFrom.orderId",
                                      },
                                    },
                                    _vm._l(_vm.groupOrderData, function (item) {
                                      return _c(
                                        "Option",
                                        {
                                          key: item.id,
                                          attrs: { value: item.id },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.groupUnitName) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "身份证号" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入身份证号",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.idCard,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "idCard",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.idCard",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "体检日期" } },
                                    [
                                      _c("DatePicker", {
                                        staticStyle: { width: "185px" },
                                        attrs: {
                                          transfer: "",
                                          clearable: false,
                                          placeholder: "请选择",
                                          type: "daterange",
                                        },
                                        on: {
                                          "on-change": _vm.registDateChange,
                                        },
                                        model: {
                                          value: _vm.selectDate,
                                          callback: function ($$v) {
                                            _vm.selectDate = $$v
                                          },
                                          expression: "selectDate",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "FormItem",
                                { attrs: { label: "审核状态" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      staticStyle: { width: "185px" },
                                      attrs: { "label-in-value": "" },
                                      model: {
                                        value: _vm.queryFrom.statu,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryFrom, "statu", $$v)
                                        },
                                        expression: "queryFrom.statu",
                                      },
                                    },
                                    _vm._l(
                                      _vm.approveStatus,
                                      function (item, index) {
                                        return _c(
                                          "Option",
                                          {
                                            key: index,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "姓名" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入姓名",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.personName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryFrom,
                                              "personName",
                                              $$v
                                            )
                                          },
                                          expression: "queryFrom.personName",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "性别" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          staticStyle: { width: "185px" },
                                          attrs: {
                                            transfer: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.queryFrom.sex,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryFrom,
                                                "sex",
                                                $$v
                                              )
                                            },
                                            expression: "queryFrom.sex",
                                          },
                                        },
                                        [
                                          _c(
                                            "Option",
                                            { attrs: { value: "男" } },
                                            [_vm._v("男")]
                                          ),
                                          _c(
                                            "Option",
                                            { attrs: { value: "女" } },
                                            [_vm._v("女")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.drop
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "年龄" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          placeholder: "请输入年龄",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.queryFrom.age,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.queryFrom, "age", $$v)
                                          },
                                          expression: "queryFrom.age",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "FormItem",
                                {
                                  staticClass: "br",
                                  staticStyle: { "margin-left": "-35px" },
                                },
                                [
                                  _c(
                                    "Button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.getPersonInfo },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "Button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.handleReset },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "drop-down",
                                      on: { click: _vm.dropDown },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.dropDownContent) + " "
                                      ),
                                      _c("Icon", {
                                        attrs: { type: _vm.dropDownIcon },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.queryFrom.statu == 0
                    ? _c(
                        "div",
                        { staticClass: "operation" },
                        [
                          _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload",
                              },
                              on: { click: _vm.inspectAll },
                            },
                            [_vm._v("批量审核")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "Row",
                    { attrs: { justify: "end" } },
                    [
                      _c("Table", {
                        ref: "table",
                        staticStyle: { width: "100%" },
                        attrs: {
                          loading: _vm.tableLoading,
                          border: "",
                          columns: _vm.columns,
                          sortable: "custom",
                          data: _vm.tableData,
                        },
                        on: {
                          "on-row-click": _vm.tableClick,
                          "on-selection-change": _vm.showSelect,
                        },
                      }),
                      _c(
                        "Row",
                        {
                          staticClass: "page",
                          attrs: { type: "flex", justify: "end" },
                        },
                        [
                          _c("Page", {
                            attrs: {
                              current: _vm.queryFrom.pageNumber,
                              total: _vm.total,
                              "page-size": _vm.queryFrom.pageSize,
                              "page-size-opts": [10, 15],
                              size: "small",
                              "show-total": "",
                              "show-elevator": "",
                              "show-sizer": "",
                              transfer: "",
                            },
                            on: {
                              "on-change": _vm.changePage,
                              "on-page-size-change": _vm.changePageSize,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Col",
            {
              style: {
                "padding-right": _vm.outRightSpan == 9 ? "20px" : "2px",
              },
              attrs: { span: _vm.outRightSpan },
            },
            [
              _c("Alert", { staticClass: "alert" }, [_vm._v("检查详情")]),
              _c("Card", { staticClass: "card" }, [
                _vm.templateShow == false
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "font-size": "15px",
                          color: "#000000",
                          "text-align": "center",
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "center",
                        },
                      },
                      [_vm._v(" 暂无数据 ")]
                    )
                  : _c(
                      "div",
                      { staticStyle: { width: "100%", height: "100%" } },
                      [
                        _vm.isTemplateShow
                          ? _c("templateCertificate", {
                              ref: "templateCertificate",
                              staticStyle: {
                                height: "100vh",
                                overflow: "hidden",
                              },
                              attrs: {
                                personIds: _vm.personIds,
                                personInfo: _vm.templateData,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }